<template>
  <v-tooltip
    v-if="text"
    :text="text"
  >
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        icon="mdi-information-slab-circle"
        density="comfortable"
        variant="text"
        size="small"
        color="info"
      />
    </template>
  </v-tooltip>
</template>

<script lang="ts">
export default {
    name: 'FormTooltip',
    props : {
        text : { type : String, required : false, default : "" },
    }
}
</script>
