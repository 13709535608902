<script lang="ts">
import FormTooltip from './Tooltip.vue'

export default {
    components: { FormTooltip },
    props: {
        title: { type: String, required: false, default: null },
        textTooltip: { type: String, required: false, default: '' },
        withBordersColor: { type: String, required: false, default: '' },
        hideSection: { type: Boolean, required: false },
    },
}
</script>

<template>
    <v-sheet
        v-if="!hideSection"
        :border="withBordersColor ? `${withBordersColor} sm` : null"
        :class="withBordersColor ? 'border-opacity-100 mb-4' : 'pa-2 ma-2'"
    >
        <div
            v-if="title" class="text-body-1 font-weight-bold"
            :class="withBordersColor && `pa-2 bg-${withBordersColor}`"
        >
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="title" />
            <FormTooltip :text="textTooltip" />
        </div>
        <v-divider v-if="!withBordersColor" class="mb-5" />
        <div :class="withBordersColor && 'pa-4 pb-2'">
            <slot />
        </div>
    </v-sheet>
    <div v-else>
        <slot />
    </div>
</template>
