<template>
  <div :class="rule ? 'mb-1' : showLabel && 'mb-3'">
    <label
      v-if="showLabel"
      class="v-label"
      :class="rule && 'mb-1'"
    >
      {{ $translate && $translate(`${translationName}.${dataKey}`) }}
      {{ validation && validation.required ? '*' : '' }}
    </label>

    <div class="text-caption text-medium-emphasis pb-1" v-if="textCaption">{{ textCaption }}</div>

    <FormTooltip
      v-if="textTooltip"
      :text="textTooltip"
    />
    <InfoCard
      v-if="textInfoCard"
      :text="textInfoCard"
    />

    <slot />
  </div>
</template>

<script lang="ts">
import FormTooltip from "./Tooltip.vue";
import InfoCard from "../InfoCard.vue";

export default {
    name : "FormLabel",
    components: {InfoCard, FormTooltip},
    computed : {
        showLabel () {
          return !this.noLabel || (this.labelSidePosition && !this.rule)
        },
        labelSidePosition () { // FOR YESNO
            return this.$parent.labelSidePosition
        },
        noLabel () {
            return this.$parent.noLabel
        },
        textInfoCard () {
            return this.$parent.textInfoCard
        },
        textTooltip () {
            return this.$parent.textTooltip
        },
        textCaption() {
            return this.$parent.textCaption
        },
        dataKey () {
            return this.$parent.dataKey
        },
        translationName () {
            return this.$parent.translationName
        },
        validation () {
            return this.$parent.validation
        },
        rule () {
            return this.$parent.rule
        },
    },
}
</script>
